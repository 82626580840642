<template>
  <v-card class="menu-localizer-card" elevation="2" :disabled="!alternativeLanguages.length">
    <v-toolbar class="menu-localizer-card-toolbar" flat>
      <v-toolbar-title
        class="menu-localizer-card-toolbar__select-language"
        v-if="mode === 'translate'"
      >
        {{ defaultLanguage | visibleLanguage }}
        <v-icon class="mx-3">mdi-arrow-right</v-icon>
        <v-select
          v-model="translateLanguage"
          :items="alternativeLanguages"
          :menu-props="{ offsetY: true }"
          :label="$t('GlobalUI.Language')"
          hide-details
          dense
          outlined
        >
          <template #selection="{ item }">
            {{ item | visibleLanguage }}
          </template>
          <template #item="{ item }">
            {{ item | visibleLanguage }}
          </template>
        </v-select>
      </v-toolbar-title>
      <v-toolbar-title v-if="isDefaultMode">
        {{ $t("GlobalUI.DefaultLanguage") }}:
        {{ currentLanguage | visibleLanguage }}
      </v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="menu-localizer-card__body">
      <div v-if="translateLanguage || isDefaultMode" class="menu-localizer-card__controls">
        <v-text-field
          :label="$t('Menu.MenuTitle')"
          v-model="title"
          :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
          :readonly="isDefaultMode"
          @click:append="isDefaultMode ? copyTitle() : undefined"
        />
        <v-text-field
          :label="$t('Menu.InternalComment')"
          v-model="internalComment"
          :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
          :readonly="isDefaultMode"
          @click:append="isDefaultMode ? copyInternalComment() : undefined"
        />
      </div>
      <span v-else>Select the language for translation</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { sync, get } from "vuex-pathify";

import copyField from "../copyField";

const GET_TITLE = "localizer/menu@title";
const GET_INTERNAL_COMMENT = "localizer/menu@internalComment";

export default {
  name: "VMenuLocalizerCard",

  mixins: [copyField],

  props: ["menu", "mode"],

  computed: {
    title: sync(`${GET_TITLE}.:currentLanguage`),
    internalComment: sync(`${GET_INTERNAL_COMMENT}.:currentLanguage`),
    translateLanguage: sync("localizer/translateLanguage"),

    defaultLanguage: get("localizer/defaultLanguage"),
    alternativeLanguages: get("localizer/alternativeLanguages"),
    isMenuBusy: get("localizer/isMenuBusy"),

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  methods: {
    copyTitle() {
      this.copy(GET_TITLE);
    },
    copyInternalComment() {
      this.copy(GET_INTERNAL_COMMENT);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-localizer-card-toolbar {
  &__select-language {
    width: 300px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}
</style>