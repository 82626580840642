<template>
  <v-card class="dish-localizer-card" elevation="2" :disabled="!alternativeLanguages.length">
    <v-toolbar class="dish-localizer-card-toolbar" flat>
      <v-toolbar-title
        class="dish-localizer-card-toolbar__select-language"
        v-if="mode === 'translate'"
      >
        {{ defaultLanguage | visibleLanguage }}
        <v-icon class="mx-3">mdi-arrow-right</v-icon>
        <v-select
          v-model="translateLanguage"
          :items="alternativeLanguages"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :label="$t('GlobalUI.Language')"
          hide-details
          dense
          outlined
        >
          <template #selection="{ item }">
            {{ item | visibleLanguage }}
          </template>
          <template #item="{ item }">
            {{ item | visibleLanguage }}
          </template>
        </v-select>
      </v-toolbar-title>
      <v-toolbar-title v-if="isDefaultMode">
        Default language: {{ defaultLanguage | visibleLanguage }}
      </v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="dish-localizer-card__body">
      <div v-if="translateLanguage || isDefaultMode" class="dish-localizer-card__controls">
        <v-text-field
          :label="$t('Menu.ItemTitle')"
          v-model="title"
          :readonly="isDefaultMode"
          :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
          @click:append="isDefaultMode ? copyTitle() : () => {}"
        />

        <v-textarea
          v-model="description"
          :label="$t('Menu.Description')"
          rows="4"
          :readonly="isDefaultMode"
          :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
          @click:append="isDefaultMode ? copyDescription() : () => {}"
          auto-grow
          dense
        />

        <div class="dish-localizer-block dish-localizer-options" v-if="options.length">
          <h6 class="text-h6 mb-0">Options</h6>
          <v-dish-options-localizer-item
              class="mb-2"
              v-for="(option, idx) in options"
              :mode="mode"
              :option="option"
              :optionsLength="options.length"
              :categoryPosition="categoryPosition"
              :dishPosition="dishPosition"
              :position="idx"
              :key="idx"
          />
        </div>

        <div class="dish-localizer-block dish-localizer-extras" v-if="extras.length">
          <h6 class="text-h6 mb-0">Extras</h6>
          <v-dish-extras-localizer-item
              class="mb-2"
              v-for="(extra, idx) in extras"
              :mode="mode"
              :extra="extra"
              :categoryPosition="categoryPosition"
              :dishPosition="dishPosition"
              :position="idx"
              :key="idx"
          />
        </div>

        <div class="dish-localizer-block dish-localizer-ingredients" v-if="ingredients.length">
          <h6 class="text-h6 mb-0">Ingredients</h6>
          <v-dish-ingredients-localizer-item
            v-for="(ingredient, idx) in ingredients"
            :mode="mode"
            :ingredient="ingredient"
            :categoryPosition="categoryPosition"
            :dishPosition="dishPosition"
            :position="idx"
            :key="idx"
          />
        </div>

        <div class="dish-localizer-block dish-localizer-allergens" v-if="allergens.length">
          <h6 class="text-h6 mb-0">Allergens</h6>
          <v-dish-allergens-localizer-item
            v-for="(allergen, idx) in allergens"
            :mode="mode"
            :allergen="allergen"
            :categoryPosition="categoryPosition"
            :dishPosition="dishPosition"
            :position="idx"
            :key="idx"
          />
        </div>

        <div class="dish-localizer-block dish-localizer-tags" v-if="tags.length">
          <h6 class="text-h6 mb-0">Tags</h6>
          <v-dish-tags-localizer-item
            v-for="(tag, idx) in tags"
            :mode="mode"
            :allergen="tag"
            :categoryPosition="categoryPosition"
            :dishPosition="dishPosition"
            :position="idx"
            :key="idx"
          />
        </div>
      </div>

      <span v-else>Select the language for translation</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { sync, get } from "vuex-pathify";

import VDishOptionsLocalizerItem from "../items/VDishOptionsLocalizerItem";
import VDishExtrasLocalizerItem from "../items/VDishExtrasLocalizerItem";
import VDishIngredientsLocalizerItem from "../items/VDishIngredientsLocalizerItem";
import VDishAllergensLocalizerItem from "../items/VDishAllergensLocalizerItem";
import VDishTagsLocalizerItem from "../items/VDishTagsLocalizerItem";

import copyField from "../copyField";

const GET_TITLE = (categoryPosition = ":categoryPosition", dishPosition = ":dishPosition") => {
  return `localizer/menu@categories[${categoryPosition}].dishes[${dishPosition}].title`;
};

const GET_DESCRIPTION = (
  categoryPosition = ":categoryPosition",
  dishPosition = ":dishPosition",
) => {
  return `localizer/menu@categories[${categoryPosition}].dishes[${dishPosition}].description`;
};

export default {
  name: "VDishLocalizerCard",

  mixins: [copyField],

  components: {
    VDishOptionsLocalizerItem,
    VDishExtrasLocalizerItem,
    VDishIngredientsLocalizerItem,
    VDishAllergensLocalizerItem,
    VDishTagsLocalizerItem,
  },

  props: ["dish", "mode"],

  data() {
    return {
      options: this.dish.options,
      extras: this.dish.extras,
      ingredients: this.dish.ingredients,
      allergens: this.dish.allergens,
      tags: this.dish.tags,
    };
  },

  computed: {
    title: sync(`${GET_TITLE()}.:currentLanguage`),
    description: sync(`${GET_DESCRIPTION()}.:currentLanguage`),
    translateLanguage: sync("localizer/translateLanguage"),

    categories: get("localizer/menu@categories"),
    defaultLanguage: get("localizer/defaultLanguage"),
    alternativeLanguages: get("localizer/alternativeLanguages"),
    isMenuBusy: get("localizer/isMenuBusy"),

    categoryPosition() {
      return this.categories.findIndex((category) => this.dish.categoryId === category.id);
    },

    dishPosition() {
      return this.categories[this.categoryPosition].dishes.findIndex(
        (dish) => this.dish.id === dish.id,
      );
    },

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  watch: {
    dish(dish) {
      this.ingredients = dish.ingredients;
      this.allergens = dish.allergens;
      this.tags = dish.tags;
    },
  },

  methods: {
    copyTitle() {
      const path = GET_TITLE(this.categoryPosition, this.dishPosition);
      this.copy(path);
    },
    copyDescription() {
      const path = GET_DESCRIPTION(this.categoryPosition, this.dishPosition);
      this.copy(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.dish-localizer-card-toolbar {
  &__select-language {
    width: 300px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}
.dish-localizer-block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);;
  padding-bottom: 16px;
  margin-bottom: 12px;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
</style>