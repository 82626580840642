<template>
  <v-card class="category-localizer-card" elevation="2" :disabled="!alternativeLanguages.length">
    <v-toolbar class="category-localizer-card-toolbar" flat>
      <v-toolbar-title
        class="category-localizer-card-toolbar__select-language"
        v-if="mode === 'translate'"
      >
        {{ defaultLanguage | visibleLanguage }}
        <v-icon class="mx-3">mdi-arrow-right</v-icon>
        <v-select
          v-model="translateLanguage"
          :items="alternativeLanguages"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :label="$t('GlobalUI.Language')"
          hide-details
          dense
          outlined
        >
          <template #selection="{ item }">
            {{ item | visibleLanguage }}
          </template>
          <template #item="{ item }">
            {{ item | visibleLanguage }}
          </template>
        </v-select>
      </v-toolbar-title>
      <v-toolbar-title v-if="isDefaultMode">
        Default language: {{ currentLanguage | visibleLanguage }}
      </v-toolbar-title>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="category-localizer-card__body">
      <div v-if="translateLanguage || isDefaultMode" class="category-localizer-card__controls">
        <v-text-field
          label="Title"
          v-model="title"
          :readonly="isDefaultMode"
          :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
          @click:append="isDefaultMode ? copyTitle() : () => {}"
          hide-details
        />
      </div>
      <span v-else>Select the language for translation</span>
    </v-card-text>
  </v-card>
</template>

<script>
import { sync, get } from "vuex-pathify";

import copyField from "../copyField";

const GET_TITLE = (categoryPosition = ":categoryPosition") => {
  return `localizer/menu@categories[${categoryPosition}].title`;
};

export default {
  name: "VCategoryLocalizerCard",

  mixins: [copyField],

  props: ["category", "mode"],

  computed: {
    title: sync("localizer/menu@categories[:categoryPosition].title.:currentLanguage"),
    translateLanguage: sync("localizer/translateLanguage"),

    categories: get("localizer/menu@categories"),
    defaultLanguage: get("localizer/defaultLanguage"),
    alternativeLanguages: get("localizer/alternativeLanguages"),
    isMenuBusy: get("localizer/isMenuBusy"),

    categoryPosition() {
      return this.categories.findIndex((category) => this.category.id === category.id);
    },

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  methods: {
    copyTitle() {
      const path = GET_TITLE(this.categoryPosition);
      this.copy(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-localizer-card-toolbar {
  &__select-language {
    width: 300px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}
</style>