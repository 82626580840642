<template>
  <v-card class="menu-localizer-tree" elevation="2">
    <template v-if="menu">
      <v-toolbar
        class="menu-localizer-toolbar"
        :class="{ 'menu-localizer-toolbar--active': !selectedCategory && !selectedDish }"
        @click="setSelectedMenu"
        flat
      >
        <v-toolbar-title class="menu-localizer-title">
          {{ menu.title[menuSettings.defaultLanguage] }}
        </v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <div v-if="accordionState.length" class="category-list">
        <v-menu-localizer-tree-item
          v-for="(category, idx) in menu.categories"
          :accordion-state="accordionState[idx]"
          :category="category"
          :multiple="false"
          :key="category.id"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types as LOCALIZER_TYPES } from "../../../../store/localizer/types";

import VMenuLocalizerTreeItem from "../items/VMenuLocalizerTreeItem";

export default {
  name: "VMenuLocalizerTreeCard",

  components: { VMenuLocalizerTreeItem },

  data() {
    return {
      accordionState: [],
      unsubscribe: () => {},
    };
  },

  computed: {
    ...mapGetters({
      menuSettings: "localizer/menuSettings",
      menu: "localizer/menu",
      selectedCategory: "localizer/selectedCategory",
      selectedDish: "localizer/selectedDish",
    }),
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === `localizer/${LOCALIZER_TYPES.REQUESTED_MENU_SUCCEEDED}`) {
        const { categories } = mutation.payload;
        this.accordionState = categories.map((category, idx) => ({
          id: category.id,
          isShow: idx === 0,
        }));
      }
    });
  },

  destroyed() {
    this.unsubscribe();
  },

  methods: mapActions({ setSelectedMenu: "localizer/setSelectedMenu" }),
};
</script>

<style lang="scss" scoped>
.menu-localizer-tree {
  height: 100%;
}

.menu-localizer-toolbar {
  &--active {
    background-color: $secondary-color !important;
  }
}

.menu-localizer-title {
  font-weight: bold;
}

.v-application.theme--dark {
  .menu-localizer-toolbar {
    &--active {
      background-color: $primary-color !important;
    }
  }
}
</style>