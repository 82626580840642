<template>
  <div
    class="category-item"
    :class="{
      'category-item--active':
        selectedCategory && !selectedDish && category.id === selectedCategory.id,
    }"
    @click.prevent="handleClickCategory(category)"
  >
    <div class="category-item__content">
      <div class="category-item__title">
        {{ category.title[menuSettings.defaultLanguage] }}
        <b v-if="category.dishes.length">
          - {{ category.dishes.length }}
          <span class="text-lowercase">{{ $t("Menu.Items") }}</span>
        </b>
      </div>
      <div class="category-item__actions">
        <v-btn v-if="category.dishes.length" @click.stop="toggleOpenDish" icon>
          <v-icon v-if="!accordionState.isShow">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </div>
    <transition
      name="dish-list"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div v-if="accordionState.isShow" class="dish-list">
        <div
          class="dish-item"
          :class="{
            'dish-item--active': selectedDish && dish.id === selectedDish.id,
          }"
          v-for="dish in category.dishes"
          :key="dish.id"
          @click.stop="handleClickDish(category, dish)"
        >
          <div class="dish-item__content">
            <div class="dish-item__title">
              {{ dish.title[menuSettings.defaultLanguage] }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "VMenuLocalizerTreeItem",

  props: ["accordionState", "category", "multiple"],

  watch: {
    accordionState: {
      handler(val) {
        console.log(val);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters({
      menuSettings: "localizer/menuSettings",
      selectedCategory: "localizer/selectedCategory",
      selectedDish: "localizer/selectedDish",
    }),
  },

  methods: {
    ...mapActions({
      setSelectedCategory: "localizer/setSelectedCategory",
      setSelectedDish: "localizer/setSelectedDish",
    }),

    toggleOpenDish() {
      if (this.multiple) {
        // this.$store.set(`localizer/menu@categories[${this.categoryPosition}].isShow`, true);
        return;
      }
      this.$parent.$children.forEach((item) => {
        if (!item.category) return;

        if (item.category.id === this.category.id) {
          item.accordionState.isShow = !item.accordionState.isShow;
        } else if (item.accordionState.isShow) {
          item.accordionState.isShow = false;
        }
      });
    },

    handleClickCategory(category) {
      this.setSelectedCategory(category.id);
    },

    handleClickDish(category, dish) {
      this.setSelectedDish({ categoryId: category.id, dishId: dish.id });
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },

    endTransition(el) {
      el.style.height = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.category-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &--active {
    .category-item__content {
      background-color: $secondary-color;
    }
  }
  &__content {
    width: 100%;
    min-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    padding-right: 5px;
  }
}

.dish-list {
  overflow: hidden;
}

.dish-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &--active {
    .dish-item__content {
      background-color: $secondary-color;
    }
  }
  &__content {
    width: 100%;
    min-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    padding-left: 32px;
  }
}

.v-application.theme--dark {
  .category-item {
    &--active {
      .category-item__content {
        background-color: $primary-color;
      }
    }
  }

  .dish-item {
    &--active {
      .dish-item__content {
        background-color: $primary-color;
      }
    }
  }
}

.dish-list-enter-active,
.dish-list-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.dish-list-enter,
.dish-list-leave-to {
  height: 0 !important;
}
</style>