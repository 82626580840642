import { Component, Vue } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";

import { confirm } from "../helpers/dialogs";

@Component
export default class ConfirmBeforeLeaveLocalizerMenu extends Vue {
  async beforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext) {
    const isMenuEqualCache = this.$store.getters["localizer/isMenuEqualCache"];
    if (isMenuEqualCache) next();
    else {
      const result = await this.$dialog.warning(
        confirm(undefined, "You have unsaved data! Do you want to save it now?", {
          cancel: {
            key: "cancel",
            text: String(this.$t("GlobalUI.Cancel")),
            flat: false,
          },
          discardChanges: {
            key: "discard",
            color: "error",
            text: String(this.$t("GlobalUI.DiscardChanges")),
            flat: false,
          },
          save: {
            key: "save",
            color: "primary",
            text: String(this.$t("GlobalUI.Save")),
            flat: false,
          },
        }),
      );

      switch (result) {
        case "cancel":
          break;
        case "discardChanges":
          this.$store.dispatch("localizer/discardMenu");
          next();
          break;
        case "save":
          await this.$store.dispatch("localizer/saveMenu");
          next();
          break;
        default:
          break;
      }
    }
  }
}
