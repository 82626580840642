<template>
  <v-card class="menu-localizer-editor" elevation="2">
    <v-toolbar flat>
      <v-spacer></v-spacer>

      <v-btn v-if="!isMenuEqualCache" @click="discard" class="mr-2" color="warning">
        Discard
      </v-btn>
      <v-btn @click="save" :disabled="isMenuEqualCache" color="primary"> Save </v-btn>
    </v-toolbar>

    <v-divider />

    <div class="menu-localizer-grid">
      <template v-if="selectedCategory && !selectedDish">
        <v-category-localizer-card :category="selectedCategory" mode="default" />
        <v-category-localizer-card :category="selectedCategory" mode="translate" />
      </template>
      <template v-if="selectedCategory && selectedDish">
        <v-dish-localizer-card :dish="selectedDish" mode="default" />
        <v-dish-localizer-card :dish="selectedDish" mode="translate" />
      </template>

      <template v-if="menu && !selectedCategory && !selectedDish">
        <v-menu-localizer-card :menu="menu" mode="default" />
        <v-menu-localizer-card :menu="menu" mode="translate" />
      </template>
    </div>
  </v-card>
</template>

<script>
import { sync, get, call } from "vuex-pathify";
import { mapGetters } from "vuex";

import VMenuLocalizerCard from "./VMenuLocalizerCard";
import VCategoryLocalizerCard from "./VCategoryLocalizerCard";
import VDishLocalizerCard from "./VDishLocalizerCard";

export default {
  name: "VMenuLocalizerEditorCard",

  components: {
    VMenuLocalizerCard,
    VCategoryLocalizerCard,
    VDishLocalizerCard,
  },

  computed: {
    menu: sync("localizer/menu"),
    isMenuEqualCache: get("localizer/isMenuEqualCache"),

    ...mapGetters({
      selectedCategory: "localizer/selectedCategory",
      selectedDish: "localizer/selectedDish",
    }),
  },

  methods: {
    save: call("localizer/saveMenu"),
    discard: call("localizer/discardMenu"),
  },
};
</script>

<style lang="scss" scoped>
.menu-localizer-editor {
  height: 100%;
}

.menu-localizer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}
</style>