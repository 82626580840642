<template>
  <v-text-field
    v-model="tag"
    :readonly="isDefaultMode"
    :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
    @click:append="isDefaultMode ? copyTag() : undefined"
    hide-details="auto"
  />
</template>

<script>
import { sync, get } from "vuex-pathify";

import copyField from "../copyField";

const GET_TAG = (
  categoryPosition = ":categoryPosition",
  dishPosition = ":dishPosition",
  position = ":position",
) => {
  return `localizer/menu@categories[${categoryPosition}].dishes[${dishPosition}].tags[${position}].text`;
};

export default {
  name: "VDishTagsLocalizerItem",

  mixins: [copyField],

  props: ["mode", "categoryPosition", "dishPosition", "position"],

  computed: {
    tag: sync(`${GET_TAG()}.:currentLanguage`),

    translateLanguage: get("localizer/translateLanguage"),
    defaultLanguage: get("localizer/defaultLanguage"),
    isMenuBusy: get("localizer/isMenuBusy"),

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  methods: {
    copyTag() {
      const path = GET_TAG(this.categoryPosition, this.dishPosition, this.position);
      this.copy(path);
    },
  },
};
</script>