<template>
  <div class="">
    <span class="subtitle-2">Option #{{ position + 1 }}:</span>
    <v-text-field
        v-if="optionsLength !== 1"
        class="mb-2"
        v-model="optionTitle"
        :readonly="isDefaultMode"
        :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
        @click:append="isDefaultMode ? copy('title') : undefined"
        hide-details="auto"
        solo
    >
      <template v-slot:prepend-inner><small>Title:</small></template>
    </v-text-field>
    <v-text-field
        v-model="optionAmount"
        :readonly="isDefaultMode"
        :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
        @click:append="isDefaultMode ? copy('amount') : undefined"
        hide-details="auto"
        solo
    >
      <template v-slot:prepend-inner><small>Amount:</small></template>
    </v-text-field>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify";

import copyField from "../copyField";

const GET_OPTION = (options) => {
  const defaultOptions = {
    categoryPosition: ":categoryPosition",
    dishPosition: ":dishPosition",
    position: ":position",
    field: 'title'
  }
  const opts = { ...defaultOptions, ...options }
  return `localizer/menu@categories[${opts.categoryPosition}].dishes[${opts.dishPosition}].options[${opts.position}].${opts.field}`;
};

export default {
  name: "VDishOptionsLocalizerItem",

  mixins: [copyField],

  props: ["mode", "optionsLength", "categoryPosition", "dishPosition", "position"],

  computed: {
    optionTitle: sync(`${GET_OPTION({ field: 'title' })}.:currentLanguage`),
    optionAmount: sync(`${GET_OPTION({ field: 'amount' })}.:currentLanguage`),

    translateLanguage: get("localizer/translateLanguage"),
    defaultLanguage: get("localizer/defaultLanguage"),
    isMenuBusy: get("localizer/isMenuBusy"),

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  methods: {
    copy(field) {
      const path = GET_OPTION({
        categoryPosition: this.categoryPosition,
        dishPosition: this.dishPosition,
        position: this.position,
        field
      });
      this.copy(path);
    },
  },
};
</script>