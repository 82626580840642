<template>
  <v-text-field
    v-model="extra"
    :readonly="isDefaultMode"
    :append-icon="isShowCopy ? 'mdi-content-copy' : undefined"
    @click:append="isDefaultMode ? copyExtra() : undefined"
    hide-details="auto"
    solo
  >
    <template v-slot:prepend-inner>
      <small>Extra title #{{ position + 1 }}:</small>
    </template>
  </v-text-field>
</template>

<script>
import { sync, get } from "vuex-pathify";

import copyField from "../copyField";

const GET_EXTRA = (
  categoryPosition = ":categoryPosition",
  dishPosition = ":dishPosition",
  position = ":position",
) => {
  return `localizer/menu@categories[${categoryPosition}].dishes[${dishPosition}].extras[${position}].title`;
};

export default {
  name: "VDishExtrasLocalizerItem",

  mixins: [copyField],

  props: ["mode", "categoryPosition", "dishPosition", "position"],

  computed: {
    extra: sync(`${GET_EXTRA()}.:currentLanguage`),

    translateLanguage: get("localizer/translateLanguage"),
    defaultLanguage: get("localizer/defaultLanguage"),
    isMenuBusy: get("localizer/isMenuBusy"),

    isDefaultMode() {
      return this.mode === "default";
    },

    currentLanguage() {
      if (this.mode === "default") {
        return this.defaultLanguage;
      }
      return this.translateLanguage;
    },
  },

  methods: {
    copyExtra() {
      const path = GET_EXTRA(this.categoryPosition, this.dishPosition, this.position);
      this.copy(path);
    },
  },
};
</script>