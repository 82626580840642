import { get } from "vuex-pathify";

export default {
  computed: {
    translateLanguage: get("localizer/translateLanguage"),
    defaultLanguage: get("localizer/defaultLanguage"),

    isShowCopy() {
      return this.mode === "default" && this.translateLanguage;
    },
  },
  methods: {
    copy(path) {
      const defaultValue = this.$store.get(`${path}.${this.defaultLanguage}`);
      this.$store.set(`${path}.${this.translateLanguage}`, defaultValue);
    },
  },
};
