<template>
  <div id="menu-localizer-constructor" class="menu-localizer-constructor">
    <div class="menu-localizer-constructor-grid mt-3">
      <div class="menu-localizer-constructor-col">
        <v-menu-localizer-tree-card />
      </div>
      <div class="menu-localizer-constructor-col">
        <v-menu-localizer-editor-card />
      </div>
    </div>
  </div>
</template>

<script>
import VMenuLocalizerTreeCard from "./cards/VMenuLocalizerTreeCard.vue";
import VMenuLocalizerEditorCard from "./cards/VMenuLocalizerEditorCard.vue";

export default {
  name: "VMenuLocalizerManagement",

  components: { VMenuLocalizerTreeCard, VMenuLocalizerEditorCard },

  data() {
    return {};
  },

  destroyed() {
    this.$store.dispatch("localizer/clearLocalizer");
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.menu-localizer-constructor {
  flex-grow: 1;
}

.menu-localizer-constructor-grid {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(320px, 1fr) 3fr;
  gap: 10px;
}

@media screen and (max-width: 991px) {
  .menu-localizer-constructor-grid {
    grid-template-columns: 100%;
  }
}
</style>

