<template>
  <div class="menu-localizer">
    <v-menu-localizer-management />
  </div>
</template>

<script>
import confirmBeforeLeaveLocalizerMenu from "../../mixins/confirmBeforeLeaveLocalizerMenu";

import VMenuLocalizerManagement from "../../components/menus/localizer/VMenuLocalizerManagement";

export default {
  name: "MenuLocalizer",

  layout: "default",

  mixins: [confirmBeforeLeaveLocalizerMenu],

  metaInfo() {
    return { title: "Menu Localizer" };
  },

  components: {
    VMenuLocalizerManagement,
  },

  watch: {
    ["$route"]() {
      this.fetchMenu();
    },
  },

  mounted() {
    this.fetchMenu();
  },

  methods: {
    async fetchMenu() {
      const menuId = this.$route.params.menuId;
      this.$store.dispatch("localizer/fetchMenu", menuId);
    },
  },
};
</script>
